<template>
  <div>
    <b-modal
      v-model="showModal"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      :hide-footer="true"
      :title="article.title"
      lazy
      static
    >
      <div v-if="article.blocs">
        <!-- <v-jsoneditor
          v-model="blocs"
          :options="options"
          height="600px"
          @error="errorJson"
        /> -->
        <quill-editor-snow style="display: none;" />
        <app-collapse>
          <app-collapse-item title="Réglages générales">
            <b-row>
              <b-col :md="6">
                <p>Titre:</p>
                <b-form-input
                  v-model="article.title"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
              <b-col :md="6">
                <p>Slug:</p>
                <b-form-input
                  v-model="article.slug"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
              <b-col :md="6">
                <p>Categorie:</p>
                <b-form-input
                  v-model="article.category"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
              <b-col :md="6">
                <p>Image:</p>
                <b-form-input
                  v-model="article.image.url"
                  class="mr-4"
                  style="width: 100%"
                />
                <img v-if="article.image.url" :src="article.image.url" style="width: 200px" />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="SEO">
            <b-row>
              <b-col :md="6">
                <p>Titre:</p>
                <b-form-input
                  v-model="article.seo.title"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
              <b-col :md="6">
                <p>Categorie (Fil d'ariane):</p>
                <b-form-input
                  v-model="article.categoryAriane"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
              <b-col :md="12">
                <p>Question / Réponse ( Schema ):</p>
                <b-form-textarea
                  v-model="article.faqSchema"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Blocs">
            <b-row
              class="my-2"
            >
              <b-col
                :key="key"
                cols="12"
                class="primary my-4"
              >
                <p>Premier contenu:</p>
                <quill-editor
                  v-model="article.firstContent"
                  :options="snowOption"
                  style="width: 100%"
                />
              </b-col>
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <div>
                  <b-row
                    v-for="(value, key) of article.blocs"
                    :key="key"
                  >
                    <b-col
                      :key="key"
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      :key="key"
                      cols="6"
                      class="primary my-4"
                    >
                      <p>Contenu:</p>
                      <quill-editor
                        v-if="value"
                        v-model="value.content"
                        :options="snowOption"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      :key="key"
                      cols="1"
                      class="primary my-4"
                    >
                      <p>Style image:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.type"
                        placeholder="full/col/hide"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      :key="key"
                      cols="2"
                      class="primary my-4"
                    >
                      <p>Image:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.image"
                        placeholder="Lien..."
                        class="mr-4"
                        style="width: 100%"
                      />
                      <b-col>
                        <b-img :src="value.image" fluid />
                      </b-col>
                    </b-col>
                    <!-- <b-col
                      :key="key"
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="article.blocs.splice(key, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col> -->
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="() => blocs.push({title: '', icon: '', text: ''})"
                    >
                      <span class="text-nowrap">Ajouter un bloc</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Produit">
            <b-row>
              <b-col :md="6">
                <v-select
                  v-model="productId"
                  placeholder="Produit"
                  label="name"
                  :options="productSelect"
                  :reduce="product => product.id"
                  :clearable="false"
                  class="invoice-filter-select mt-1"
                  transition=""
                />
                {{ article.product }}
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <b-row
          class="my-2"
        >
          <b-col
            span="12"
            class="primary d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              @click="updateValue()"
            >
              <span class="text-nowrap">Enregistrer</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-card title="Vous pouvez modifier les articles de blog directement ici.">
      <b-row class="match-height">
        <b-col
          md="3"
          lg="3"
          class="mb-4"
        >
          <b-button
            variant="primary"
            @click="createArticle()"
          >
            Créer un nouvel article
          </b-button>
        </b-col>
        <b-col
          cols="3"
          md="3"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="country"
            placeholder="Pays"
            label="Pays"
            :options="countryOptions"
            :clearable="false"
            class="invoice-filter-select"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col
          v-for="pdct in articles"
          :key="pdct.id"
          md="3"
          lg="3"
          class=""
        >
          <b-card
            v-if="pdct"
            class="ecommerce-card"
            no-body
          >
            <div
              v-if="pdct.image"
              class="item-img text-center"
            >
              <b-img
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="pdct.image.url"
              />
            </div>

            <!-- pdct Details -->
            <b-card-body>
              <h4 class="item-name">
                {{ pdct.title }}
              </h4>
              <!-- <b-card-text class="item-description">
                {{ article.short_description }}
              </b-card-text> -->
            </b-card-body>

            <!-- article Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="openModal(pdct)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Modifier l'article</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BModal,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BButton,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import QuillEditorSnow from '@core/components/quill-editor/QuillEditorCustom.vue'
import { Quill, quillEditor } from 'vue-quill-editor'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BImg,
    BButton,
    BCardBody,
    QuillEditorSnow,
    AppCollapse,
    AppCollapseItem,
    BFormInput,
    BFormTextarea,
    quillEditor,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  watch: {
    country: {
      deep: true,
      handler: 'getarticles',
    },
  },
  data() {
    return {
      articles: [],
      article: {},
      showModal: false,
      options: { mode: 'code' },
      blocs: [],
      productSelect: [],
      productId: null,
      firstContent: '',
      country: 'FR',
      countryOptions: ['ES', 'FR', 'DE', 'IT', 'NL', 'EN', 'UK'],
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
        },
      },
      whithoutOption: {
        theme: 'snow',
        modules: {
          toolbar: [],
        },
      },
    }
  },
  async mounted() {
    try {
      const Block = Quill.import('blots/block')
      Block.tagName = 'div'
      Quill.register(Block, true)
      await this.getarticles()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getarticles() {
      try {
        const { articles } = await this.$http.get(`/admin/blog/?country=${this.country}`)
        this.articles = articles
      } catch (err) {
        console.log(err)
      }
    },
    async createArticle() {
      const { products } = await this.$http.get(`/admin/blog/list-product/?country=${this.country}`)
      this.productSelect = products
      this.article = {
        blocs: [],
        firstContent: '',
        productId: null,
        country: this.country,
        isNew: true,
        slug: '',
        title: '',
        image: {
          url: '',
          alt: '',
        },
        category: '',
        seo: {},
      }
      this.blocs = []
      this.firstContent = ''
      this.productId = null
      this.showModal = true
    },
    async openModal(article) {
      this.showModal = true
      const { products } = await this.$http.get(`/admin/blog/list-product/?country=${article?.country}`)
      this.productSelect = products
      this.article = article
      this.blocs = article.blocs
      this.firstContent = article.firstContent
      this.productId = article._product
      this.article.seo = this.article.seo || {}
    },
    convertHtml(html) {
      // eslint-disable-next-line newline-per-chained-call
      return html?.replaceAll('<span style="color: rgb(0, 0, 0);">', '')?.replaceAll('</span>', '')?.replaceAll('style="color: rgb(0, 0, 0);"', '')
    },
    async updateValue() {
      try {
        this.article.firstContent = this.convertHtml(this.article.firstContent)
        this.article.blocs = this.article.blocs.map(v => ({ ...v, content: this.convertHtml(v.content) }))
        if (this.article.isNew) {
          await this.$http.post('admin/blog/', { article: this.article })
        } else {
          await this.$http.put(`admin/blog/${this.article._id}`, { article: this.article })
        }
        await this.getarticles()
        this.showModal = false
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Enregistrement réussi',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (err) {
        console.log(err)
      }
    },
    errorJson(err) {
      console.log(err)
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
